import React from 'react'

import styles from './NotFound.css'

export function NotFound() {
  return (
    <div className={styles.page}>
      404
    </div>
  )
}