import React from 'react'
import { Routes, BrowserRouter as Router, Route } from 'react-router-dom'
import { Home } from './pages/Home'
import { NotFound } from './pages/NotFound'

import styles from './App.css'

export default function App() {
  return (
    <AppContext>
      <AppImpl/>
    </AppContext>
  )
}

export function AppImpl() {
  return (
    <div className={styles.component_rootImpl}>
      <div className={styles.main}>
        <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
        </Router>
      </div>
    </div>
  )
}

function AppContext({children }) {
  return (
    <>
      {children}
    </>
  )
}