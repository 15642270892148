import React from 'react'

import styles from './Home.css'

export function Home() {
  return (
    <div className={styles.page}>
      Soon
    </div>
  )
}